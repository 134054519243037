import { Box, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useAppContext } from '../../contexts/SidebarContext';

interface IProps {
  onAgree: () => void;
}

export default function UserConsent({ onAgree }: IProps) {
  const { setOpenModal, setUploading } = useAppContext();

  const handleOnAgree = () => {
    setOpenModal(false);
    setUploading(true);
    sessionStorage.setItem('opened', 'true');
    onAgree();
  };

  return (
    <>
      <Box id="alert-dialog-title" className="modal-title-wrapper">
        <Typography variant="h5" color="#0099FF">
          Welcome to Anyline <span style={{ color: '#212121' }}>Cloud API</span>
        </Typography>
      </Box>
      <DialogContent>
        <Box id="alert-dialog-description">
          <Typography variant="caption" color="#212121">
            Thanks for your interest in giving our product a try!
          </Typography>
          <div>
            <Typography variant="caption">
              With this showcase, you can scan elements from uploaded images.
            </Typography>
          </div>
          <div>
            <Typography variant="caption">
              The Cloud API supports the following use cases :
            </Typography>
          </div>
          <ul>
            <li>
              {' '}
              <Typography variant="caption" color="#212121">
                Meters (Analog/Digital)
              </Typography>
            </li>
            <li>
              <Typography variant="caption" color="#212121">
                Meters and Meter Identification (Analog/Digital and
                Barcode/Serial Number)
              </Typography>
            </li>
            <li>
              <Typography variant="caption" color="#212121">
                Identity Documents (ID layouts and MRZ)
              </Typography>
            </li>
            <li>
              <Typography variant="caption" color="#212121">
                MRZ (Passports, Visas,..)
              </Typography>
            </li>
            <li>
              <Typography variant="caption" color="#212121">
                Tire Sidewall
              </Typography>
            </li>
            <li>
              <Typography variant="caption" color="#212121">
                Barcode (1D and 2D symbologies)
              </Typography>
            </li>
          </ul>
          <div></div>
          <Typography variant="caption">
            To learn more about our products, please go to our{' '}
            <a
              href="https://anyline.com/products"
              rel="noreferrer"
              className="alink"
              target="_blank"
            >
              Products Page.
            </a>
          </Typography>
          <Grid container className="modal-container">
            <Grid item xs={12}>
              <Typography color="#212121" variant="h6">
                Declaration of Consent
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Please note that this application is going to process visual
                data on your device. In accordance with local data protection
                regulations you may require a legal basis for processing such
                data (e.g. legitimate interest, consent declaration, etc) and it
                is your responsibility to comply with local data protection
                regulations. If personal data is transmitted in connection with
                the use of this application we will process this personal data
                according to our{' '}
                <a
                  href="https://anyline.com/imprint-and-legal"
                  className="alink"
                  rel="noreferrer"
                  target="_blank"
                >
                  Web Privacy Policy.
                </a>{' '}
                Our current{' '}
                <a
                  href="https://anyline.com/imprint-and-legal"
                  rel="noreferrer"
                  className="alink"
                  target="_blank"
                >
                  Terms of Use for the Anyline Apps
                </a>{' '}
                apply to your use of the application.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions className="modal-btn">
        <Button
          onClick={handleOnAgree}
          sx={{
            '&.MuiButtonBase-root:hover': {
              bgcolor: '#000',
            },
          }}
        >
          <img src="./group11.png" alt="" />
          <Typography variant="subtitle2">Agree</Typography>
        </Button>
      </DialogActions>
    </>
  );
}
