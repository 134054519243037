import { ErrorBoundary } from 'react-error-boundary';
import './App.css';
import Layout from './components/Layout';
import MainContent from './components/MainContent/MainContent';
import Navbar from './components/Navbar';
import Footer from './components/footer/Footer';
import Sidebar from './components/sidebar/Sidebar';
import { SidebarProvider } from './contexts/SidebarContext';

import './styles/Common.scss';
import UserGate from './components/UserGate/UserGate';

function App() {
  return (
    <div className="App">
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <SidebarProvider>
          {process.env.REACT_APP_IS_USER_GATE_SELECTED === 'true' && (
            <UserGate />
          )}
          <Navbar />
          <Layout sidebar={<Sidebar />} mainContent={<MainContent />} />
          <Footer />
        </SidebarProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
