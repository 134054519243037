import React, { Suspense } from 'react';
import LazyCheckmark from './Checkmark/LazyCheckmark';
import LazyLightBulb from './LightBulb/LightBulb';
import LazyPerson from './Person/LazyPerson';
import LazyThunder from './Thunder/LazyThunder';
import LazyAlert from './Alert/LazyAlert';
import LazyCloud from './Cloud/LazyCloud';
import LazyFile from './File/LazyFile';
import LazyCheckmarkUserGate from './CheckMarkUserGate/LazyCheckMarkUserGate';
import LazyViewFinder from './ViewFinder/LazyViewFinder';
import LazyGoBack from './GoBack/LazyGoBack';
import LazyGallery from './Gallery/LazyGallery';
import LazyCameraAccess from './CameraAccess/LazyCameraAccess';
import LazyQRCode from './QRCode/LazyQRCode';
import LazyExpired from './Expired/LazyExpired';

interface IconList {
  Checkmark: React.FC<{}>;
  LightBulb: React.FC<{}>;
  Person: React.FC<{}>;
  Thunder: React.FC<{}>;
  Alert: React.FC<IconProps>;
  Cloud: React.FC<IconProps>;
  CheckmarkUserGate: React.FC<{}>;
  File: React.FC<{}>;
  ViewFinder: React.FC<{}>;
  GoBack: React.FC<{}>;
  Gallery: React.FC<{}>;
  CameraAccess: React.FC<{}>;
  QRCode: React.FC<{}>;
  Expired: React.FC<{}>;
}

interface IconProps {
  fill?: string;
  width?: string;
  height?: string;
}

const Icon: React.FC & IconList = () => null;

// eslint-disable-next-line react/display-name
Icon.Checkmark = () => (
  <Suspense fallback={null}>
    <LazyCheckmark />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.LightBulb = () => (
  <Suspense fallback={null}>
    <LazyLightBulb />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.Person = () => (
  <Suspense fallback={null}>
    <LazyPerson />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.Thunder = () => (
  <Suspense fallback={null}>
    <LazyThunder />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.Alert = ({ fill, width, height }: IconProps) => (
  <Suspense fallback={null}>
    <LazyAlert fill={fill} width={width} height={height} />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.File = () => (
  <Suspense fallback={null}>
    <LazyFile />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.Cloud = ({ fill }: IconProps) => (
  <Suspense fallback={null}>
    <LazyCloud fill={fill} />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.CheckmarkUserGate = () => (
  <Suspense fallback={null}>
    <LazyCheckmarkUserGate />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.ViewFinder = () => (
  <Suspense fallback={null}>
    <LazyViewFinder />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.GoBack = () => (
  <Suspense fallback={null}>
    <LazyGoBack />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.Gallery = () => (
  <Suspense fallback={null}>
    <LazyGallery />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.CameraAccess = () => (
  <Suspense fallback={null}>
    <LazyCameraAccess />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.QRCode = () => (
  <Suspense fallback={null}>
    <LazyQRCode />
  </Suspense>
);

// eslint-disable-next-line react/display-name
Icon.Expired = () => (
  <Suspense fallback={null}>
    <LazyExpired />
  </Suspense>
);

export default Icon;
