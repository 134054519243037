import { useEffect } from 'react';
import scanModesMetaData, {
  type EScanModePreselectName,
  type IScanModeMetaData,
} from '../../../../static/scanModesMetaData';
import CardLayout from '../../../card/CardLayout';
import Grid from '@mui/material/Grid';
import { useAppContext } from '../../../../contexts/SidebarContext';

export default function SideBarTiles() {
  const {
    setIsClicked,
    setCardId,
    setActiveStep,
    setScanMode,
    setSelectedCard,
  } = useAppContext();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modeFromUrl = urlParams.get('scanmode');
    // check if the requested scanmode is a known one
    const chosenMode: IScanModeMetaData[] = scanModesMetaData.filter(
      (item) => item.preselectName === (modeFromUrl as EScanModePreselectName),
    );

    if (chosenMode.length) {
      setIsClicked(true);
      setScanMode(chosenMode[0].scanMode);
      setCardId(chosenMode[0].id);
      setSelectedCard(chosenMode[0]);
      setActiveStep(2);
    }
  }, []);

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ele: IScanModeMetaData,
  ) => {
    setIsClicked(true);
    setCardId(ele.id);
    setScanMode(ele.scanMode);
    setSelectedCard(ele);
    setActiveStep(2);

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('scanmode', ele.preselectName);
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  return (
    <>
      {scanModesMetaData
        ?.filter((v: IScanModeMetaData) => v.active)
        .map((ele: IScanModeMetaData) => (
          <Grid
            key={ele.scanMode}
            item
            sm={12}
            xs={6}
            md={6}
            onClick={(e) => {
              handleClick(e, ele);
            }}
            className="sidebar-modes"
          >
            <CardLayout dataSet={ele} />
          </Grid>
        ))}
    </>
  );
}
