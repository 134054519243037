import React, {
  createContext,
  type Context,
  useState,
  useContext,
} from 'react';
import footerLinksMetaData, {
  type IFooterLinksMetaData,
} from '../static/footerLinksMetaData';
import { type IScanModeMetaData } from '../static/scanModesMetaData';

interface IAppContext {
  error: boolean;
  setError: (error: boolean) => void;
  isClicked: boolean;
  setIsClicked: (isClicked: boolean) => void;
  dataApi: any;
  setDataApi: (dataApi: any) => void;
  style: string;
  setStyle: (style: string) => void;
  cardId: string | null | undefined; // CAPI-306
  setCardId: (cardId: string | null) => void;
  file: Blob | undefined;
  setFile: (file: Blob) => void;
  activeStep: number;
  setActiveStep: (activeStep: number) => void;
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  modalSession: number;
  setModalSession: (session: number) => void;
  uploading: boolean;
  setUploading: (uploading: boolean) => void;
  previewImage: string | null;
  setPreviewImage: (image: string) => void;
  footerData: IFooterLinksMetaData[];
  setFooterData: (footerData: IFooterLinksMetaData[]) => void;
  scanMode: string;
  setScanMode: (scanMode: string) => void;
  currentProgress: number;
  setCurrentProgress: (currentProgress: number) => void;
  cookieConcent: boolean;
  setCookieConcent: (cookieConcent: boolean) => void;
  selectedCard: IScanModeMetaData | undefined;
  setSelectedCard: (selectedCard: IScanModeMetaData) => void;
  endpointID: string;
  setEndpointID: (endpointID: string) => void;
  gcfSlug: string;
  setGcfSlug: (gcfSlug: string) => void;
  engineSlug: string;
  setEngineSlug: (engineSlug: string) => void;
  companyName: string;
  setCompanyName: (companyName: string) => void;
}

export const GlobalAppContext: Context<IAppContext | undefined> = createContext<
  IAppContext | undefined
>(undefined);

export const useAppContext = (): IAppContext => {
  const context = useContext(GlobalAppContext);

  if (!context) {
    throw new Error('Fatal error, SidebarContext is not defined');
  }

  return context;
};

export const SidebarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [error, setError] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [dataApi, setDataApi] = useState('');
  const [style, setStyle] = useState('cardStyle');
  const [cardId, setCardId] = useState<string | null | undefined>(); // CAPI-306
  const [file, setFile] = useState<Blob>();
  const [activeStep, setActiveStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [modalSession, setModalSession] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [footerData, setFooterData] =
    useState<IFooterLinksMetaData[]>(footerLinksMetaData);
  const [scanMode, setScanMode] = useState('');
  const [currentProgress, setCurrentProgress] = useState(1);
  const [cookieConcent, setCookieConcent] = useState(false);
  const [selectedCard, setSelectedCard] = useState<
    IScanModeMetaData | undefined
  >(false as any); // CAPI-306
  const [endpointID, setEndpointID] = useState('');
  const [gcfSlug, setGcfSlug] = useState('');
  const [engineSlug, setEngineSlug] = useState('');
  const [companyName, setCompanyName] = useState('');

  return (
    <GlobalAppContext.Provider
      value={{
        error,
        setError,
        isClicked,
        setIsClicked,
        dataApi,
        setDataApi,
        style,
        setStyle,
        cardId,
        setCardId,
        file,
        setFile,
        activeStep,
        setActiveStep,
        openModal,
        setOpenModal,
        modalSession,
        setModalSession,
        uploading,
        setUploading,
        previewImage,
        setPreviewImage,
        footerData,
        setFooterData,
        scanMode,
        setScanMode,
        currentProgress,
        setCurrentProgress,
        cookieConcent,
        setCookieConcent,
        selectedCard,
        setSelectedCard,
        endpointID,
        setEndpointID,
        gcfSlug,
        setGcfSlug,
        engineSlug,
        setEngineSlug,
        companyName,
        setCompanyName,
      }}
    >
      {children}
    </GlobalAppContext.Provider>
  );
};
